.profileInfo {
  text-align: left;
  flex-basis: 25%;
  flex-grow: 0;
  max-width: 100%;
  margin-top: 55px;
}
.profileInfo__photo {
  display: block;
  width: 150px;
  margin: 0 !important;
  /*^ I hate this but its needed TEMP */
}

/* Revert button styling */
.profileInfo__userName,
.profileInfo__userAttribute,
.profileInfo__userAttribute--readonly {
  background: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  color: inherit;
  padding: 0;
}

.profileInfo__userName {
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 15px;
  font-size: 24px;
  font-weight: 700;
}
.profileInfo__userName > label {
  font-weight: bold;
}
.profileInfo__userName:hover {
  color: var(--color4);
}
.profileInfo__userName:before {
  content: "";
  width: 65px;
  height: 1px;
  border-bottom: 1px solid var(--color1);
  position: absolute;
  bottom: 0;
}
.profileInfo__userName--edit:hover {
  color: var(--color1);
}
.profileInfo__userName--edit {
  display: flex;
}
.profileInfo__userName--edit > input {
  flex-grow: 2;
  margin: 0 0 0 5px;
}
.profileInfo__userAttribute,
.profileInfo__userAttribute--readonly {
  margin-bottom: 30px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.profileInfo__userAttribute > span {
  cursor: pointer;
}
.profileInfo__userAttribute > strong,
.profileInfo__userAttribute--readonly > strong {
  display: block;
}
.profileInfo__userAttribute > span::after {
  content: "✎";
  opacity: 0;
}
.profileInfo__userAttribute > span:hover::after {
  opacity: 1;
}
.profileInfo__userAttribute > span:hover {
  color: var(--color4);
}
.profileInfo__userAttribute > input,
.profileInfo__userAttribute > textarea {
  width: 100%;
  margin: 0px 0px -2px 0px;
}
.profileInfo__userAttribute > label {
  font-weight: bold;
  white-space: nowrap;
  display: block;
}
.profileInfo__userAttribute--readonly {
  word-break: break-all;
}

.profileInfo__logout {
  font-size: 20px;
  padding-left: 0;
}
.profileInfo__logout:hover {
  color: var(--color4);
}

.profileInfo__form-buttons {
  display: flex;
  justify-content: space-between;
}

.profile-info__address {
  margin-bottom: 8px;
}

@media (orientation: portrait) {
  .profileInfo__userAttribute--edit {
    flex-wrap: wrap;
  }

  .profileInfo__userAttribute--edit > * {
    margin-bottom: 5px;
    font-size: 16px;
    margin-bottom: 0;
  }
  .profileInfo__userName--edit > * {
    margin-bottom: 5px;
  }
  .profileInfo__userAttribute > span:after {
    content: "✎";
  }

  .profileInfo__userName--edit {
    flex-wrap: wrap;
  }
}
