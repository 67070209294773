.profileAssets {
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  flex-basis: 75%;
}

.profileAssets__collection {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5% 0 5%;
  justify-content: left;
  max-width: 100%;
}

.profileAssets__collectionItem {
  flex-basis: 50%;
}

.user-collection__start-collecting-button > button {
  margin-top: 30px;
}
.user-collection__start-collecting-wrapper {
  flex-basis: 100%;
}

.profileAssets__header {
  text-align: right;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5%;
}
button.profileAssets__headerItem {
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  margin-left: 30px;
  padding: 0 0 5px 0;
  color: var(--color5);
}
.profileAssets__headerItem:hover {
  color: var(--color4);
  cursor: pointer;
}
button.profileAssets__headerItem--current {
  font-weight: bold;
  color: var(--color4);
  border-bottom: 1px solid var(--color4);
}

@media (orientation: portrait) {
  .profileAssets__collection {
    padding: 0px;
    align-items: stretch;
  }

  .profileAssets__collectionItem {
    flex-basis: 100%;
  }

  .profileAssets__header {
    text-align: left;
    margin-top: 30px;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  button.profileAssets__headerItem {
    margin-left: 0px;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .profileAssets__headerItem:last-of-type {
    margin-right: 0px;
  }
  div.profileAssets__collection {
    padding: 0px;
  }
  .wallet__address {
    width: calc(100vw - 10%);
  }
}
