.Toastify__toast {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  font-weight: 300;
}

/* Custom background colors */
.Toastify__toast--dark {
  background: var(--color3);
}
.Toastify__toast--error {
  background: var(--color6);
}

/* Progress bar */
.Toastify__progress-bar--dark {
  background: var(--color4);
}

/* Close button */
.Toastify__close-button--dark {
  color: var(--color4);
}
.Toastify__close-button--dark:hover,
.Toastify__close-button--dark:focus {
  color: var(--color5);
}

.Toastify__close-button--error,
.Toastify__close-button--error:hover,
.Toastify__close-button--error:focus {
  color: var(--color1);
}
