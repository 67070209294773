@charset "UTF-8";

/* === GLOBALS START === */

:root {
  --color1: #ffffff;
  --color2: #181818;
  --color3: #242424;
  --color4: #a47a44;
  --color5: #cfab72;
  --color6: #ff0033;
}
body {
  color: var(--color1);
  background-color: var(--color2);
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  /*font-variant: small-caps;*/
  letter-spacing: 2px;
  align-content: flex-start;
  font-weight: 300;
}
input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#root {
  width: 100%;
}

#loader {
  margin: auto;
  width: min-content;
}
/* TEMP too many consequences */
/* model-viewer{
    position: absolute;
    height:80%;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

model-viewer {
  --poster-color: transparent;
  --progress-bar-height: 5px;
  --progress-bar-color: var(--color4);
  --progress-mask: url("~/assets/images/loader.gif") no-repeat center;
  width: 100%;
  height: 100%;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* === GLOBALS END === */

/* TEMP removing and replacing */
/* #account .flex div{
    cursor: pointer;
    text-align: center;
} */
.br,
.br--padded {
  margin: auto;
  width: 96%;
  height: 1px;
  background-color: var(--color1);
}
#account .flex div .br,
.br--padded {
  margin: 15%;
  width: 70%;
}
#nav {
  top: 64px;
  height: 32px;
}
#content {
  top: 96px;
  padding: 50px 96px 64px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1600px;
}
#footer {
  display: none;
  bottom: 0px;
  height: 32px;
  padding: 0 32px;
}

/*page specific*/
#default {
  overflow-x: hidden;
}
/*gallery*/
#gallery model-viewer {
  width: 100%;
  height: 100%;
}
#gallery #header,
#gallery #footer,
#gallery #sidebar {
  display: none;
}
#gallery #content {
  padding: 0;
  top: 0;
}

/* TEMP remove lot wrappings */
/* #lotBox{
    padding: 5%;
    word-break: break-word;
}
#lotBox>.button{
    padding:16px;
}
#lotBox>*{
    padding: 5%;
} */
/* #lotBox model-viewer{
    width: 200;
    height: auto;
    position: relative;
    height: 300;
    float: left;
} */
#collectionBox {
  word-break: break-word;
  padding: 5%;
}
#assetList .box {
  padding: 32px 32px 0;
}
#assetList model-viewer {
  height: 190px;
}
h1 {
  font-weight: 700;
  margin-top: 0;
}
h2 {
  font-family: "Montserrat", sans-serif;
}
#collectionBox > * {
  padding: 5%;
}
#collectionBox model-viewer {
  width: 200;
  height: auto;
  position: relative;
  height: 300;
  float: left;
}

#modelSelect {
  position: absolute;
  bottom: 0;
  top: 80%;
  height: 20%;
  overflow-y: hidden;
  /* We set the scroll snapping */
  scroll-snap-type: x mandatory;
  /* Necessary for mobile scrolling */
  -webkit-overflow-scrolling: touch;
  /* For layout purposes */
  display: flex;
  overflow-x: scroll;

  text-align: center;
  scroll-behavior: smooth;
}
#modelSelect > * {
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* Set where the snapping should happen */
  scroll-snap-align: start;
  scroll-behavior: smooth;
}
#minimizeButton {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 16px;
  bottom: 16px;
  transform: rotate(180deg);
}
#maximizeButton {
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: calc(20% + 16px);
  left: 16px;
}
#nextButton {
  position: absolute;
  transform: rotate(270deg);
  width: 40px;
  height: 40px;
  bottom: calc(10% - 20px);
  right: 16px;
  z-index: 20;
}
#gallery #beebeeate-widget {
  display: none;
}
.popup {
  padding: 5% 35% 5% 35%;
  font-size: 16px;
  color: var(--color1);
  background-color: var(--color2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 6;
}
.popup > div {
  background-color: var(--color2);
  padding: 16px;
  font-size: 18px;
  margin: 32px auto;
  width: 50%;
}
.popup a {
  color: var(--color4);
}
input,
textarea,
.popup textarea {
  display: initial;
  background-color: var(--color2);
  color: var(--color1);
  margin: 3% 25%;
  width: 50%;
  font-size: 22px;
  border: 0px;
  border-bottom: 2px solid var(--color1);
}

select {
  background-color: var(--color1);
  font-family: "Montserrat", sans-serif;
}

.pad {
  padding: 0 6%;
}
.mobile {
  display: none;
}
.mobile img {
  width: 100%;
}
.box {
  background-color: var(--color1);
  color: var(--color2);
  border: 2px solid var(--color2);
  margin: 5px;
  padding: 5px;
  overflow: auto;
}
.box input {
  display: initial;
  color: var(--color1);
  background-color: var(--color2);
  border-color: var(--color1);
  margin: 0 5%;
  width: 89.8%;
}
.button,
.button--block {
  color: var(--color1);
  background-color: var(--color2);
  cursor: pointer;
  border: 2px solid var(--color1);
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 16px;
  margin: 50px 0;
  text-transform: uppercase;
  font-weight: 700;
}
.button {
  margin: 50px auto;
}
.button--block {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}
.button--blank,
.button--plain {
  background: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  color: inherit;
}
.button--plain {
  padding: 0;
}
#lot .button {
  color: var(--color2);
  background-color: var(--color1);
}
.button:hover {
  color: var(--color4);
  border: 2px solid var(--color4);
}
a:link {
  color: var(--color4);
  text-decoration: none;
}

a:visited {
  color: var(--color4);
  text-decoration: none;
}
a:visited {
  color: var(--color4);
}
a:hover {
  color: var(--color5);
}
a:active {
  color: var(--color5);
}
.flex {
  display: flex;
}
.flex > * {
  flex: 1;
}
.inline-block {
  display: inline-block;
}
.w1 {
  width: 100%;
}
.w5 {
  width: 50%;
}

/** Lets a link to the standard text color. */
a.link--text {
  color: var(--color1);
  text-decoration: inherit;
}
a.link--text:hover {
  color: var(--color4);
}

/* "h-*" classes are helper classes that have very few styles that can be used across components.
helper classes should e used sparingly 
*/
.h-100pw {
  width: 100%;
}
.h-position-relative {
  position: relative;
}
.h-flex-g1 {
  flex-grow: 1;
}
.h-flex {
  display: flex;
}

.h-form-error {
  color: var(--color6);
  font-size: 80%;
}

@media (orientation: portrait) {
  .mobile {
    display: initial;
  }
  #content {
    padding-left: 5%;
    padding-right: 5%;
  }
  .loginOption__image {
    height: 100px;
  }
  .popup {
    padding: 5%;
  }
  .popup input,
  .popup textarea {
    width: 100%;
    margin: 3% 0%;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .wrap > * {
    flex-basis: 100%;
  }
  h2 {
    font-size: 16px;
  }
  body {
    font-size: 14px;
  }
  .market__collectionItem {
    flex-basis: 100%;
  }
  .market__filterHeading {
    font-size: 28px;
    float: inherit;
  }
  .subhead__nav {
    text-align: left;
    margin-top: 0px;
  }
  .subhead__navItem:first-of-type {
    padding-left: 0px;
  }
  .subhead__navItem {
    padding-left: 15px;
  }
  .subHead__tagCloud {
    text-align: left;
    padding-left: 0px;
  }
  .subHead__tagItem {
    margin-left: 0px;
    margin-right: 10px;
  }
}
#collection #footer {
  display: none;
}
#account .flex img:hover {
  filter: brightness(0.5);
}
