.artist-list-item {
  position: relative;
  display: block;
  line-height: 0;
}

.artist-list-item__banner {
  width: 100%;
}

.artist-list-item__name {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.artist-list-page__search {
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 16px 0;
}
