.auction__input {
  margin: 0;
  width: 100%;
  display: block;
  text-align: right;
  margin-bottom: 30px;
}
.auction__label {
  display: block;
  margin-bottom: 5px;
}
.auction__attribute {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
.auction__bidder {
  margin-bottom: 10px;
}
.auction__bidButton {
  width: 100%;
  letter-spacing: 2px;
}
.auction__history {
  margin-bottom: 10px;
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.auction__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-image: linear-gradient(to bottom, transparent, var(--color2));
}

.auction__remit-message {
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0;
}

@media (orientation: portrait) {
  .auction__attribute {
    font-size: 16px;
  }
}
