.model-preview {
  position: relative;
}

.model-preview__glb-wrapper {
  position: relative;
  padding-top: 100%;
}
.model-preview__image-wrapper {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}
.model-preview__image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.model-preview__glb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.model-preview__video {
  width: 100%;
}

.model-preview__buttons {
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.model-preview__button {
  box-sizing: border-box;
  margin-top: 5px;
  color: var(--color1);
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  background: transparent;
  border: none;
  max-width: 20%;
  font-size: 14px;
  padding: 0;
  font-weight: 300;
  letter-spacing: 2px;
  align-items: stretch;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
a.model-preview__button {
  color: var(--color1);
}

.model-preview__button:hover {
  color: var(--color5);
}
.model-preview__button--active {
  font-weight: 800;
  color: var(--color5);
}

.model-preview__button-image {
  height: 100%;
  border-radius: 100%;
}

.model-preview__button-icon {
  background-color: transparent;
  color: var(--color1);
  height: 100%;
  width: 100%;
  padding: 5px 30px 5px 30px;
  box-sizing: border-box;
}

.model-preview__glb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@media (orientation: portrait) {
  .model-preview__button-icon {
    padding: 2px 20px 2px 20px;
  }
}
