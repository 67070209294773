/* lotAssets */
.lotAsset__title {
  display: inline-block;
  margin-bottom: 50px;
  position: relative;
  padding-bottom: 15px;
  vertical-align: top;
}
.lotAsset__title:before {
  content: "";
  width: 65px;
  height: 1px;
  border-bottom: 1px solid var(--color1);
  position: absolute;
  bottom: 0;
}
.lotAsset__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.lotAsset__content {
  display: flex;
  flex-basis: 100%;
  text-align: center;
  width: 100%;
}
.lotAsset__name {
  position: relative;
  padding-bottom: 8px;
}
.lotAsset__name a {
  color: var(--color1);
}
.lotAsset__name:before {
  content: "";
  width: 25px;
  height: 1px;
  border-bottom: 1px solid var(--color1);
  position: absolute;
  bottom: 0;
}
.lotAsset__attribute {
  margin-bottom: 10px;
}
.lotAsset__details {
  flex-basis: 33%;
  text-align: left;
  padding: 30px;
  /*align-self: flex-end;*/
  position: relative;
}
.lotAsset__viewer {
  flex-basis: 33%;
  position: relative;
  margin-bottom: 30px;
}
.lotAsset__auction {
  flex-basis: 33%;
  padding-top: 30px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lotAsset__model {
  display: block;
  height: auto;
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.lotAsset__modelShare {
  margin: 0 5px;
  position: relative;
  bottom: -50px;
  font-size: 30px;
}
.lotAsset__shareInput {
  position: absolute;
  left: -999em;
}
.lotAsset__shareWrapper {
  margin-bottom: 10px;
  margin-bottom: 15px;
}
.lotAsset__linkouts {
  display: inline-block;
  text-align: right;
  font-size: 24px;
  width: calc(50% - 7px);
  padding-left: 0px;
  font-weight: bold;
}
.lotAsset__linkouts li {
  display: inline-block;
  margin-left: 10px;
}
.lotAsset__linkouts li:first-of-type {
  margin-left: 0px;
}
.lotAsset__tags {
  display: block;
  position: relative;
  bottom: -50px;
}

.share__twitterIcon {
  height: 10px;
  margin-right: 5px;
}
.share__link {
  color: var(--color1);
  background-color: var(--color2);
  cursor: pointer;
  border: 2px solid var(--color1);
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 8px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 2px;
}
a.share__twitter {
  color: var(--color1);
}
.share__facebookButton {
  margin-bottom: -9px;
}

.footerImg {
  width: 25%;
}

@media (orientation: portrait) {
  .lotAsset__auction {
    flex-basis: 100%;
    text-align: left;
    order: 2;
  }
  .lotAsset__details {
    flex-basis: 100%;
    padding: 0px;
    order: 3;
  }
  .lotAsset__viewer {
    flex-basis: 100%;
    overflow: hidden;
  }
  .lotAsset__content {
    flex-wrap: wrap;
  }
  .lotAsset__title {
    width: 100%;
    margin-bottom: 10px;
  }
  .lotAsset__linkouts {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
  .lotAsset__attribute {
    max-width: calc(100vw - 50px);
    overflow: scroll;
  }
  .lotAsset__modelShare {
    font-size: 22px;
  }

  .footerImg {
    width: 100%;
  }
}
