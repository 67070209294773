.mapCollection__wrapper {
  width: 100%;
  height: 350px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 30px;
}
.popup__title {
  font-weight: bold;
  margin-top: 10px;
  font-size: 18px;
  color: var(--color2);
}
.marker__button {
  cursor: pointer;
}
.mapCollection__saveButton {
  display: inline-block;
  width: auto;
  margin-left: 15px;
  font-size: 12px;
  margin-top: 5px;
  padding: 5px 15px;
  cursor: pointer;
}
.mapCollection__newLatLong > span {
  display: inline-block;
}
@media (orientation: portrait) {
  .mapCollection__wrapper {
    padding-bottom: 60px;
  }
}
