.illust-date-picker {
  letter-spacing: initial;
  font-family: Poppins, sans-serif;
  background: var(--color2);
}

.react-datepicker__time-container .react-datepicker__time {
  background: var(--color2);
  color: var(--color1);
}

.react-datepicker__header {
  background: var(--color3);
}

.react-datepicker__day,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: var(--color1);
}

.react-datepicker__day--selected {
  background: var(--color4);
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: var(--color1);
}
