input.create-account__input,
textarea#bioi.create-account__input {
  width: 100%;
  margin: 1% 0% 5% 0;
}

.create-account__label {
  font-weight: bold;
  font-size: 14px;
}

.create-account__heading {
  margin-bottom: -4px;
  font-size: 22px;
}

div.create-account__button,
.button {
  width: calc(100% - 36px);
}
.create-account__connectChange {
  padding-bottom: 75px;
  display: block;
  cursor: pointer;
  text-align: center;
  background: transparent;
  border: none;
  color: var(--color4);
  font-size: 18px;
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.create-account__connectChange:hover {
  color: var(--color5);
}
button.button--block.create-account__button {
  margin-bottom: 5px;
  margin-top: 5px;
}
input.create-account__checkbox {
  width: min-content;
  margin: 8px;
}
label.create-account__checkboxLabel {
  display: block;
  margin-top: 10px;
}
.create-account__text {
  display: block;
  margin: 15px 0;
}
@media (orientation: portrait) {
  .popup input.create-account__checkbox {
    width: min-content;
    margin: 8px;
  }
}
