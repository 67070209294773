.user-profile__header {
  text-align: left;
  text-transform: capitalize;
}

.br.user-profile__br {
  width: 64px;
  float: left;
  margin: 0px calc(98% - 64px) 16px 0%;
}

.user-profile__wrapper {
  display: flex;
  align-items: space-between;
}

.user-profile__sidebar {
  width: 300px;
  flex-shrink: 0;
}

.user-profile__content {
  flex-grow: 1;
}
@media (orientation: portrait) {
  .user-profile__content {
    width: 100%;
  }
}
