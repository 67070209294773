.subhead__nav {
  list-style-type: none;
  padding-left: 0;
  text-align: right;
}
.subhead__navItem {
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding-left: 30px;
  font-size: 18px;
  color: var(--color4);
  cursor: pointer;
  margin-top: 10px;
}
.subhead__navItem--active > a {
  color: var(--color1);
}
.subHead__tagCloud {
  display: block;
  text-align: right;
}
.subHead__tagItem {
  list-style: none;
  display: inline-block;
  margin-left: 15px;
}
.subhead__tagSelect {
  background: transparent;
  color: var(--color4);
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  border: none;
  cursor: pointer;
  letter-spacing: 2px;
  width: 95px;
}
@media (orientation: portrait) {
  .subhead__nav {
    clear: both;
    text-align: left;
  }
  .subhead__navItem {
    margin-top: 0px;
    margin-bottom: 25px;
  }
}
