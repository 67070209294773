#header {
  width: 100%;
}

.header {
  top: 0;
  z-index: 5;
  padding: 5% 5% 0;
}

.header,
.header__account {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500;
}

.header a,
.header a:link,
.header a:visited {
  color: inherit;
}

.header a:hover {
  color: var(--color5);
}

.header__account {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--color1);
  cursor: pointer;
  margin-bottom: 15px;
}
.header__user-icon {
  cursor: pointer;
  width: 48px;
  margin: 0 auto;
}
.header__username {
  font-size: 14px;
  margin-left: 8px;
}
.header__login-wrapper {
  display: flex;
  align-items: center;
}
.button.header__login {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  margin: -9px 0 0px 0px;
  padding: 16px;
  width: auto;
}
.header__illust {
  margin: 9px 24px 24px 24px;
  text-align: center;
  cursor: pointer;
  flex-grow: 1;
}

.header__menu-wrapper {
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 0;
}
.header__menu {
  width: 42px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
}
.header__menu-line {
  height: 3px;
  margin: 10px auto 10px auto;
  width: 90%;
  border-radius: 2px;
  background-color: var(--color1);
}
.header__menu:hover .header__menu-line {
  background-color: var(--color4);
}
.header__top-line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 4px;
}
.header__br.br {
  margin: 1% 0%;
  width: 100%;
}
@media (orientation: portrait) {
  .button.header__login {
    padding: 8px;
  }
  .header__username {
    display: none;
  }
}
