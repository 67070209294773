.wallet__address {
  max-width: 100%;
  user-select: all;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallet__label {
  font-weight: bold;
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: left;
}
.wallet__attribute {
  display: block;
  width: 100%;
  text-align: left;
}

.wallet__approve-wrapper {
  margin-top: 16px;
}
