a.collectionItem__attributes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  color: var(--color1);
}

a.collectionItem__attributes:hover {
  color: var(--color4);
}

.collectionItem__wrapper {
  margin: 10% 10% 20% 10%;
}
.collectionItem__artist {
  flex-basis: 100%;
}
.collectionItem__edition {
  flex-basis: 100%;
}
.collectionItem__title {
  margin-bottom: 15px;
}
.collectionItem__link {
  cursor: pointer;
  text-transform: uppercase;
}
.collectionItem__auctionDetails {
  display: flex;
  position: relative;
  height: 25px;
  color: var(--color1);
  justify-content: space-between;
}
.collectionItem__countdown {
  background-color: var(--color4);
  position: absolute;
  text-align: right;
  top: 0;
  z-index: -5;
  right: 0;
  height: 100%;
}
@media (orientation: portrait) {
  .collectionItem__wrapper {
    margin: 0 0 15% 0;
  }
  .auction__attribute > .collectionItem__countdown {
    right: auto;
    left: 0;
  }
}
