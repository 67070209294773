.market__collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
.market__collectionItem {
  flex-basis: 33%;
}
.market__banner {
  display: block;
  width: 100%;
}
.market__bannerImage {
  width: 100%;
  margin-bottom: 20px;
}
.market__heading {
  float: left;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.market__heading:after {
  content: "";
  width: 65px;
  height: 1px;
  border-bottom: 1px solid var(--color1);
  position: absolute;
  bottom: 0;
  left: 10px;
}
.market__filter-heading {
  font-size: 22px;
  float: left;
  font-weight: 300;
  margin-top: 0;
  clear: both;
  margin-top: -10px;
}
@media (orientation: portrait) {
  .market__filter-heading {
    margin-left: 0px;
    float: none;
  }
  .market__collectionItem {
    flex-basis: 100%;
  }
}
