.loginOption__wrapper {
  text-align: center;
  padding-top: 55px;
}
.loginOption__image {
  height: 200px;
}
.loginOption__image:hover {
  cursor: pointer;
}

.connect .connect__br {
  margin: 15%;
  width: 70%;
}
