.sidebar a,
.sidebar button,
.sidebar a:visited,
.sidebar a:link {
  color: var(--color1);
}

.sidebar {
  background-color: var(--color3);
  color: var(--color1);
  overflow: hidden;
  position: fixed;
  width: 310px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 12;
}
.sidebar > div {
  margin: 10%;
  cursor: pointer;
}

.sidebar__button {
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 0;
}

.sidebar__overlay {
  position: fixed;
  height: 100%;
  z-index: 11;
  background-color: var(--color2);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 40%;
}
